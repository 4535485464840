const mockUser: any = {
  name: 'Mock User',
  identityProvider: 'https://devvirtuswebportal.b2clogin.com/bbd08e99-b2b0-4982-b73b-19a693163004/v2.0/',
  userIdentifier: '',
  idToken: {
    exp: 1556742619,
    nbf: 1556739019,
    ver: '1.0',
    iss: 'https://devvirtuswebportal.b2clogin.com/bbd08e99-b2b0-4982-b73b-19a693163004/v2.0/',
    sub: 'b14f0f9a-8938-47b6-8476-c9d7265b1cdf',
    aud: 'c7bdc462-eeb6-4baf-81fd-9a46c9041743',
    nonce: '402d861f-eea8-4cd2-b864-099d4e0ce46e',
    iat: 1556739019,
    auth_time: 1556739019,
    oid: 'b14f0f9a-8938-47b6-8476-c9d7265b1cdf',
    given_name: 'Mock',
    family_name: 'User',
    name: 'Mock User',
    emails: ['mock.user@virtusllc.com'],
    tfp: 'B2C_1_NexusSignUpSignIn',
  },
};

export default mockUser;
