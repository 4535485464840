import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'src/app/App';
import { initStore } from 'src/app/store';
import config from 'src/config';

// Global init
let mockRoutes;
if (__USE_MOCK_DATA__) {
  mockRoutes = require('./mock').mockRoutes;
}

// Global init
const store = initStore(mockRoutes);

// Load React
const rootDOMElement = document.getElementById('app') as HTMLElement;
const render = (AppComponent = App) =>
  ReactDOM.render(
    <Provider store={store}>
      <AppComponent config={config} />
    </Provider>,
    rootDOMElement,
  );

render(App);

console.log(`
  App started. __ENVIRONMENT__ is ${__ENVIRONMENT__},
  __VERSION__ is ${__VERSION__}, NODE_ENV is ${process.env.NODE_ENV}, 
  MOCK MODE IS ${__USE_MOCK_DATA__}`);
