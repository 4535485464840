import get from 'lodash/get';
import { Reducer } from 'redux';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { User } from './msalWrapper';

export const authActions = {
  // B2C init
  initialize: createStandardAction('@auth/initialize')<any>(),
  initialized: createStandardAction('@auth/initialized')(),
  // Nexus auth actions
  login: createStandardAction('@auth/b2c/login')<any>(),
  logout: createStandardAction('@auth/b2c/logout')(),
  loginSuccess: createStandardAction('@auth/b2c/login/success')<object>(),
  loginError: createStandardAction('@auth/b2c/login/error')<string>(),
  unauthorized: createStandardAction('@auth/b2c/unauthorized')(),
  // Glide auth actions
  glideLogin: createStandardAction('@auth/glide/login')<any>(),
  glideLogout: createStandardAction('@auth/glide/logout')(),
  glideLoginSuccess: createStandardAction('@auth/glide/login/success')<GlideAuthObj>(),
  glideLoginError: createStandardAction('@auth/glide/login/error')<GlideAuthError>(),
  // Portal auth actions
  portalLogin: createStandardAction('@auth/portalLogin')(),
};

export type AuthActions = ActionType<typeof authActions>;

export type AuthStatus = 'uninitialized' | 'initializing' | 'initialized';

export type GlideAuthObj = { [key: string]: GlideAuth };
export type GlideAuthError = { statusCode: number; statusMessage?: string };

export type GlideAuth = {
  token?: string;
};

export type AuthState = {
  // after successful login
  readonly user: User | null;
  readonly token: string | null;
  // after failed login
  readonly error: string | null;
  readonly status: AuthStatus;
  readonly glide: GlideAuthObj;
  readonly glideAuthError: GlideAuthError | null;
};

export type RootState = { auth: AuthState };

export const initialState: AuthState = {
  error: null,
  status: 'uninitialized',
  token: null,
  user: null,
  glide: {},
  glideAuthError: null,
};

export const auth: Reducer<AuthState, AuthActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(authActions.loginSuccess): {
      return {
        ...state,
        ...action.payload,
        error: null,
        status: 'initialized',
      };
    }
    case getType(authActions.loginError): {
      return {
        ...initialState,
        error: action.payload,
        status: 'initialized',
      };
    }
    case getType(authActions.unauthorized): {
      return {
        ...initialState,
        status: 'initialized',
      };
    }
    case getType(authActions.logout): {
      return {
        ...initialState,
        status: state.status,
      };
    }
    case getType(authActions.initialize): {
      return {
        ...state,
        status: 'initializing',
      };
    }
    case getType(authActions.initialized): {
      return {
        ...state,
        status: 'initialized',
      };
    }
    // Glide auth actions
    case getType(authActions.glideLoginError): {
      return {
        ...initialState,
        glideAuthError: action.payload,
      };
    }
    case getType(authActions.glideLoginSuccess): {
      return {
        ...state,
        status: 'initialized',
        glide: {
          token: action.payload.token,
          environment: action.payload.environment,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export const authSelectors = {
  isAuthenticated: (state: RootState) => !!state.auth.user,
  isGlideAuthenticated: (state: RootState) => Boolean(state.auth.glide.token),
  glideAuthToken: (state: RootState) => state.auth.glide.token,
  glideAuthError: (state: RootState) => state.auth.glideAuthError,
  glideEnvironment: (state: RootState) => ({
    environment: state.auth.glide.environment,
  }),
  getStatus: (state: RootState) => state.auth.status,
  getUser: (state: RootState) => state.auth.user,
  getToken: (state: RootState) => state.auth.token,
  getUsername: (state: RootState) =>
    get(state, 'auth.user.idToken.extension_Username') || get(state, 'auth.user.name', ''),
  getUserEmails: (state: RootState): string[] =>
    get(state, 'auth.user.idToken.emails') || get(state, 'auth.user.emails', []),
  loginError: (state: RootState) => state.auth.error,
};
