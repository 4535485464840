import { AppConfig, B2CConfig, GlideConfig } from '@virtus/common/auth';

export interface GlideAppConfig extends AppConfig {
  b2c: B2CConfig;
  glide: GlideConfig;
}

// TODO: Store on .env but a pipeline will need to exist to inject env values

/**
 * returns correct GlideConfig object depending on __ENVIRONMENT__
 *
 * Using "if" statements other environments should be tree-shaked by webpack and not appear in bundled js
 */
const getConfig = (): GlideAppConfig => {
  let CYPRESS_ENV;
  if (typeof Cypress !== 'undefined') {
    CYPRESS_ENV = Cypress.env('__ENVIRONMENT__');
  }

  if (CYPRESS_ENV === 'dev' || __ENVIRONMENT__ === 'dev') {
    return {
      glide: {
        baseURL: 'https://rcm-test.alphakinetic.com:8080',
        // TODO: Register windows service on clients and secure service
        API_WEB_SOCKET_URL: 'wss://rcm-test.alphakinetic.com:8082',
        environment: 'rcm-test',
      },
      b2c: {
        appName: 'Glide DEV',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
        b2cSigninPolicy: 'B2C_1_NexusSignUpSignIn',
        b2cPasswordResetPolicy: 'B2C_1_passwordReset1',
      },
    };
  }

  if (__ENVIRONMENT__ === 'mock') {
    return {
      glide: {
        // Uncomment next line if not running the GlideWeb API locally
        baseURL: 'https://248dc3f3-2a73-499a-9fae-29d3c12868cf.mock.pstmn.io/',
      },
      b2c: {
        appName: 'Glide LOCAL',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
        b2cSigninPolicy: 'B2C_1_NexusSignUpSignIn',
        b2cPasswordResetPolicy: 'B2C_1_passwordReset1',
      },
    };
  }

  if (__ENVIRONMENT__ === 'local') {
    return {
      glide: {
        // Uncomment next line if not running the GlideWeb API locally
        baseURL: 'http://localhost:8080',
        API_WEB_SOCKET_URL: 'ws://127.0.0.1:8082',
        environment: 'local',
      },
      b2c: {
        appName: 'Glide LOCAL',
        clientId: 'd8382a07-3e15-4a3d-91c7-7cd648f43586',
        tenantId: 'devvirtuswebportal',
        userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
        b2cSigninPolicy: 'B2C_1_NexusSignInCypress',
        b2cPasswordResetPolicy: 'B2C_1_passwordReset1',
      },
    };
  }

  if (__ENVIRONMENT__ === 'qa') {
    return {
      glide: {
        baseURL: 'https://rcm-test.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://rcm-test.alphakinetic.com:8082',
        environment: 'rcm-test',
      },
      b2c: {
        appName: 'Glide QA',
        clientId: 'd5180215-4a5b-4c7f-9678-bf888118523c',
        tenantId: 'stagingvirtuswebportal',
        b2cSigninPolicy: 'B2C_1_NexusSignUpSignIn',
        userLoginApiUrl: 'https://stgnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
        b2cPasswordResetPolicy: 'B2C_1_passwordReset1',
      },
    };
  }

  if (__ENVIRONMENT__ === 'prod') {
    return {
      glide: {
        baseURL: 'https://rcm-test.alphakinetic.com:8080',
        API_WEB_SOCKET_URL: 'wss://rcm-test.alphakinetic.com:8082',
        environment: 'rcm-test',
      },
      b2c: {
        appName: 'Glide',
        clientId: '3fc10a9a-e29c-42db-9af8-5d31407be3f3',
        tenantId: 'prodvirtuswebportal',
        b2cSigninPolicy: 'B2C_1_SignUpSignIn',
        userLoginApiUrl: 'https://next.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
        b2cPasswordResetPolicy: 'B2C_1_PasswordReset',
      },
    };
  }

  throw new Error(`No config for __ENVIRONMENT__: '${__ENVIRONMENT__}'`);
};

const config = getConfig();
export default config;
