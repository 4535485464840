import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export type FillProps = { fillColor?: string };

const FindInPage: React.SFC<SvgIconProps & FillProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        fill={props.fillColor || '#FFF'}
        // tslint:disable-next-line:max-line-length
        d="M15 13.658l-2.828-2.828a5 5 0 1 0-1.414 1.414l3.89 3.89c-.361.523-.965.866-1.648.866H3a2 2 0 0 1-2-2V1a2 2 0 0 1 2-2h9l3 3v11.658zm-7-2.586a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
      />
    </g>
  </SvgIcon>
);

export default FindInPage;
