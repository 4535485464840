import b2cAuth from '@virtus/common/auth/sagas/B2CLogin';
import glideAuthSagas from '@virtus/common/auth/sagas/glideLogin';
import { runSagas } from '@virtus/common/sagas/runSagas';
import notifications from './notifications';

/**
 * add all sagas here
 */
const sagas = [...b2cAuth, ...glideAuthSagas, ...notifications];

/**
 * Call this from createStore to run all sagas as their own root.
 *
 * Will Restart each saga if it catches an error and console.error it
 * This stops one error bubbling to parent and killing all sagas
 *
 * @param sagaMiddleware pass in from redux init
 */
export const runAllSagas = (sagaMiddleware: any) => {
  runSagas(sagaMiddleware, sagas);
};
