import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

// tslint:disable:max-line-length
const PassIcon: React.FunctionComponent<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="6" fill="#FFF" />
      <path
        fill="#01B494"
        d="M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zM6.6 11.5L3.1 8l.987-.987L6.6 9.519l5.313-5.313.987.994-6.3 6.3z"
      />
    </g>
  </SvgIcon>
);

export default PassIcon;
