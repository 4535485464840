import { createMuiTheme } from '@material-ui/core/styles';
import { colors, fontFamily } from './theme';

/** material ui theme for apps */
export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.bg,
      dark: colors.bgDark,
    },
    secondary: {
      light: colors.light,
      main: colors.accent,
    },
    error: {
      light: colors.errorLight,
      main: colors.error,
      dark: colors.errorDark,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily,
    fontWeightMedium: 600, // default 500, but Open Sans only has 600
  },
  overrides: {
    MuiList: {
      // Name of the component ⚛️ / style sheet
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1214,
      lg: 1280,
      xl: 1920,
    },
  },
});
