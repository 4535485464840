import React, { ComponentClass, ComponentType, FunctionComponent, useState } from 'react';
import DefaultConfirmDialog from './DefaultConfirmDialog';

export interface ConfirmDialogProps {
  onClose?: () => void;
  onHelp?: () => void;
  show: boolean;
  headerText?: string;
  bodyTitle?: string;
  bodyTextContent?: string;
  cancelButton?: any;
  acceptButton?: any;
  onAccept?: (e?: any) => void;
  onCancel?: (e?: any) => void;
}

export interface ClickConfirmationProps extends Partial<ConfirmDialogProps> {
  onClick: (e?: any) => void;
  children?: React.ReactNode;
  DialogComponent?: ComponentType<ConfirmDialogProps>;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const useConfirmationDialog = (props: ClickConfirmationProps) => {
  const [asking, setAsking] = useState(false);
  const {
    onClick: originalOnClickHandler,
    headerText,
    bodyTitle,
    bodyTextContent,
    cancelButton,
    acceptButton,
    onConfirm,
    onCancel,
    onClose,
    onHelp,
    DialogComponent = DefaultConfirmDialog,
  } = props;
  const onAcceptHandler = (e?: any) => {
    setAsking(false);
    originalOnClickHandler(e);
    if (onConfirm) onConfirm();
  };

  const onCancelHandler = () => {
    setAsking(false);
    if (onCancel) onCancel();
  };

  const onDispatcherClick = () => setAsking(true);

  return {
    onDispatcherClick,
    DialogComponent: () => (
      <DialogComponent
        show={asking}
        headerText={headerText}
        bodyTitle={bodyTitle}
        bodyTextContent={bodyTextContent}
        cancelButton={cancelButton}
        acceptButton={acceptButton}
        onAccept={onAcceptHandler}
        onCancel={onCancelHandler}
        onClose={onClose}
        onHelp={onHelp}
      />
    ),
  };
};

const withConfirmationDialogOnClick = (DialogDispatcherComponent: FunctionComponent<any> | ComponentClass<any>) => {
  return (props: ClickConfirmationProps) => {
    const [asking, setAsking] = useState(false);
    const {
      onClick: originalOnClickHandler,
      headerText,
      bodyTitle,
      bodyTextContent,
      cancelButton,
      acceptButton,
      onConfirm,
      onCancel,
      onClose,
      onHelp,
      children,
      DialogComponent = DefaultConfirmDialog,
      ...componentProps
    } = props;
    const onAcceptHandler = (e?: any) => {
      setAsking(false);
      originalOnClickHandler(e);
      if (onConfirm) onConfirm();
    };

    const onCancelHandler = () => {
      setAsking(false);
      if (onCancel) onCancel();
    };

    const onDispatcherClick = () => setAsking(true);

    return (
      <>
        <DialogComponent
          show={asking}
          headerText={headerText}
          bodyTitle={bodyTitle}
          bodyTextContent={bodyTextContent}
          cancelButton={cancelButton}
          acceptButton={acceptButton}
          onAccept={onAcceptHandler}
          onCancel={onCancelHandler}
          onClose={onClose}
          onHelp={onHelp}
        />
        <DialogDispatcherComponent data-testid="dialog-dispatcher" {...componentProps} onClick={onDispatcherClick}>
          {children}
        </DialogDispatcherComponent>
      </>
    );
  };
};

export default withConfirmationDialogOnClick;
