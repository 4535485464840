export const appsLinks = {
  nexus: {
    local: 'http://localhost:4001',
    dev: 'https://deveus2nexusweb.azurewebsites.net',
    qa: 'https://nexusstaging.virtusconnect.com',
    prod: 'https://nexus.virtusconnect.com',
    mock: '',
  },
  glide: {
    local: 'http://localhost:5001',
    dev: 'https://deveus2glideweb.azurewebsites.net',
    qa: 'https://glidestaging.virtusconnect.com',
    prod: 'https://glide.virtusconnect.com',
    mock: '',
  },
  genesis: {
    local: '',
    dev: 'https://deveus2genesisweb.azurewebsites.net',
    qa: 'https://genesisstaging.virtusconnect.com',
    prod: 'https://genesis.virtusconnect.com',
    mock: '',
  },
};
