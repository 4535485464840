import axios, { AxiosError, AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authActions, GlideConfig } from 'src/auth';
import { authSelectors } from 'src/auth/reducer';
import getGlideAPIClient from 'src/core-services/GlideAPIClient';
import { getType } from 'typesafe-actions';
import { PayloadAction } from 'typesafe-actions/dist/types';

type initializeGlideAuthInterface = {
  token?: string;
  // Following can be overriden command line
  username?: string;
  glidePassword?: string;
};

export const glideAuthRequest = ({
  baseURL,
  token,
  username,
  environment,
}: initializeGlideAuthInterface & GlideConfig): Promise<any> => {
  const authUrl = `${baseURL}/glide/authenticate`;
  // Keep Axios here as fetch is not supported by Electron (Cypress)!
  return axios({
    url: authUrl,
    method: 'GET',
    params: {
      user_name: username,
      b2c_token: token,
      use_header_authorization: true,
      environment,
    },
  })
    .then((response: AxiosResponse) => ({
      response: {
        headers: response.headers,
        body: response.data,
      },
    }))
    .catch((error: AxiosError) => ({
      error,
    }));
};

function* glideLogin(action: PayloadAction<string, any>) {
  const token = yield select(authSelectors.getToken);
  const username = yield select(authSelectors.getUsername);
  const { error, response } = yield call(glideAuthRequest, { ...action.payload, token, username });
  if (response) {
    getGlideAPIClient(action.payload.baseURL).setAuthHeaders(response.headers);
    yield put(
      authActions.glideLoginSuccess({
        token: response.body.token,
        environment: response.headers.environment,
      }),
    );
    yield put(authActions.initialized());
  } else if (error) {
    const payload: any = {
      statusCode: error?.response?.status,
      statusMessage: error?.response?.data?.error_message ?? 'Glide authentication error',
    };
    yield put(authActions.glideLoginError(payload));
  }
}

// it would  be more elegant to watch for Glide auth here so the login flow is all contained in saga
// function* watchB2CLoginSuccess() {
//   console.log('B2C OK. Kicking Glide auth');
//   yield call(glideLogin);
// }

function* watchGlideLogin() {
  yield takeLatest(getType(authActions.glideLogin), glideLogin);
  // yield takeLatest(getType(authActions.loginSuccess), watchB2CLoginSuccess);
}

export default [watchGlideLogin];
