import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { variables } from 'src/theme';
import styled from 'styled-components';

const NexusIcon: React.SFC<Omit<SvgIconProps, 'color'> & { color?: string }> = ({ color, ...props }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color || '#57B124'}
        fillRule="nonzero"
        // tslint:disable-next-line:max-line-length
        d="M2.314 5.351l3.037-3.037c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519L6.87 9.907c-.42.419-1.1.419-1.519 0L2.314 6.87c-.419-.42-.419-1.1 0-1.519zm0 11.78l3.037-3.038c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519L6.87 21.686c-.42.419-1.1.419-1.519 0l-3.037-3.037c-.419-.42-.419-1.1 0-1.519zm11.78-11.78l3.036-3.037c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519l-3.037 3.037c-.42.419-1.1.419-1.519 0L14.093 6.87c-.419-.42-.419-1.1 0-1.519zm0 11.78l3.036-3.038c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519l-3.037 3.037c-.42.419-1.1.419-1.519 0l-3.037-3.037c-.419-.42-.419-1.1 0-1.519z"
      />
    </g>
  </SvgIcon>
);

/** correct font size for `<Header>` */
export const NexusHeaderIcon: React.SFC<{}> = () => (
  <StyledHeaderIcon>
    <NexusIcon fontSize="inherit" />
  </StyledHeaderIcon>
);

const StyledHeaderIcon = styled.span`
  font-size: ${variables.headerIconFontSize};
`;

export default NexusIcon;
