import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Apps from '@material-ui/icons/Apps';
import { appsLinks } from '@virtus/common/appLinks/appLinks';
import React, { ComponentType, useState } from 'react';
import { GenesisIcon, GlideIcon, NexusIcon } from 'src/icons';
import styled from 'styled-components';

export interface AppLink {
  name: AppNames;
  route: string;
  active?: number;
  color: string;
}

export enum AppNames {
  nexus = 'nexus',
  glide = 'glide',
  genesis = 'genesis',
}

export interface Props {
  value: string;
  enabledApps: AppNames[];
}

export interface State {
  visible: boolean;
}

const SNexusIcon = styled(NexusIcon)`
  display: flex;
  flex: 1;
  width: 22px;
  margin-bottom: 5px;
`;

const SGlideIcon = styled(GlideIcon)`
  display: flex;
  flex: 1;
  width: 22px;
  margin-bottom: 5px;
`;

const SGenesisIcon = styled(GenesisIcon)`
  display: flex;
  flex: 1;
  width: 22px;
  margin-bottom: 5px;
`;

const appMenuLinks: AppLink[] = [
  {
    name: AppNames.nexus,
    route: appsLinks.nexus[__ENVIRONMENT__],
    color: 'var(--fis-green)',
  },
  {
    name: AppNames.glide,
    route: appsLinks.glide[__ENVIRONMENT__],
    color: 'var(--default-blue)',
  },
  {
    name: AppNames.genesis,
    route: appsLinks.genesis[__ENVIRONMENT__],
    color: 'var(--purple-genesis)',
  },
];

const icons: { [key in AppNames]: ComponentType<{ color?: string }> } = {
  nexus: SNexusIcon,
  glide: SGlideIcon,
  genesis: SGenesisIcon,
};

const AppMenu: React.SFC<Props> = ({ enabledApps, value }) => {
  const [visible, setVisible]: any = useState(false);
  const [hovering, setHovering] = useState<number>(-1);
  const open = () => setVisible(true);
  const close = (_e: any) => setVisible(false);

  const links = appMenuLinks.filter(appMenuLink => enabledApps.includes(appMenuLink.name));

  return (
    <ClickAwayListener onClickAway={close}>
      <>
        <Container onClick={visible ? close : open}>
          <AppsIcon />
          <AbsoluteMenu visible={visible}>
            <MenuPointer />

            <Menu>
              {links.map((appLink: AppLink, index: number) => {
                const Icon = icons[appLink.name];
                const isSelected = appLink.name.toLowerCase() === value.toLowerCase();

                return (
                  <MenuItem
                    key={appLink.name}
                    target="_blank"
                    href={appLink.route}
                    selected={isSelected}
                    onMouseEnter={() => setHovering(index)}
                    onMouseLeave={() => setHovering(-1)}
                    style={{
                      background: isSelected ? appLink.color : index === hovering ? 'var(--grey-hover)' : 'none',
                    }}
                  >
                    <Icon color={isSelected ? 'white' : undefined} />
                    <ItemText>{appLink.name}</ItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </AbsoluteMenu>
        </Container>
      </>
    </ClickAwayListener>
  );
};

const Container: any = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  color: #333;
  padding: 4px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.14);
  }
`;

const Menu: any = styled.div`
  display: flex;
  width: 190px;
  padding: 5px;
  flex-wrap: wrap;
  color: #333;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
`;

const AbsoluteMenu: any = styled.div`
  visibility: ${(props: any) => (props.visible ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 40px;
  padding: 5px;
  z-index: 501;
`;

const MenuPointer: any = styled.div`
  width: 0px;
  height: 0px;
  margin-top: 32px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
`;

const MenuItem: any = styled.a<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  transition: background 0.2s;
  text-decoration: none;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
`;

const ItemText = styled.span`
  font-size: 13px;
  text-transform: capitalize;
`;

const AppsIcon: any = styled(Apps)`
  color: #fff;
`;

export default AppMenu;
