import { auth, AuthActions, AuthState } from '@virtus/common/auth/reducer';
import { combineReducers } from 'redux';
import { entitiesReducer as entities, queriesReducer as queries } from 'redux-query/advanced';
// Duplicate redux-query reducer to handle custom reset
import errors from 'src/reducers/errors';

/**
 * Define all actions
 */
export type Actions = AuthActions;

/**
 * Define the whole State
 */
export type RootState = {
  auth: AuthState;
  entities: any;
  queries: any;
  errors: any;
};

/**
 * define Root Reducer
 */
export const rootReducer = combineReducers<RootState>({
  auth,
  entities,
  queries,
  errors,
});

export default rootReducer;
