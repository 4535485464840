import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

// tslint:disable:max-line-length
const Layouts: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="17px" height="15px" viewBox="0 0 17 15" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard-Copy"
        transform="translate(-157.000000, -64.000000)"
        fill={props.color || '#5A5D5F'}
        fillRule="nonzero"
      >
        <path
          d="M174,79 L157,79 L157,64 L174,64 L174,79 Z M164.999,75 L157.999,75 L158,78 L164.999,78 L164.999,75 Z M172.999,75 L165.999,75 L166,78 L173,78 L172.999,75 Z M164.999,71 L158,71 L158,74 L164.999,74 L164.999,71 Z M173,71 L165.999,71 L165.999,74 L173,74 L173,71 Z M164.999,67 L157.999,67 L157.999,70 L164.999,70 L164.999,67 Z M172.999,67 L165.999,67 L165.999,70 L172.999,70 L172.999,67 Z"
          id="layout"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Layouts;
