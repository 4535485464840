import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import merge from 'lodash/merge';
import React, { useMemo, useState } from 'react';
import { colors } from 'src/theme';
import styled from 'styled-components';
import { DropdownMenuProps } from './DropdownMenu.model';
interface DropdownMenuState {
  anchorEl: HTMLElement | ((el: HTMLElement) => HTMLElement) | null;
}

/**
 * A menu of actions.
 *
 * Simple wrapper around Material UI `<Menu>`.
 *
 * children are `<DropdownMenuItem>` or `<Divider>`
 *
 * clones customButton to wrap onClick to open menu
 *
 * clones all child DropdownMenuItems to wrap their respective onClicks to close menu
 *
 * For each child DropdownMenuItem onClick you pass in the thing you want to happen when clicked:
 *
 * ```
  <DropdownMenuItem onClick={() => doSomething()} label="Click me for something"/>
 * ```
 */

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = ({
  styles = {},
  button,
  light,
  children,
  onOpen,
  onClose,
  hideButton,
  initialAnchorEl = null,
  fixAnchor = false,
  menuMarginTop = 43,
}) => {
  const [anchorEl, setAnchorEl] = useState<DropdownMenuState['anchorEl']>(initialAnchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (onOpen) onOpen();
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };
  const { button: buttonStyles, menu: menuStyles } = styles;

  const isOpen = Boolean(anchorEl);

  const calcMenuOffset = () => {
    const offsetsEl = fixAnchor && anchorEl && (anchorEl as any).getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    return {
      top: offsetsEl && offsetsEl.top - bodyRect.top,
      left: offsetsEl && offsetsEl.left - offsetsEl.width,
    };
  };

  const getMenuOffset = () => {
    const offsets = calcMenuOffset();
    return {
      top: `${offsets.top}px !important`,
      left: `${offsets.left}px !important`,
    };
  };

  const StyledMenu = useMemo(
    () =>
      withStyles({
        ...merge(menuDefaultStyles(menuMarginTop), menuStyles, {
          paper: fixAnchor && anchorEl ? getMenuOffset() : {},
        }),
      })(Menu),
    [menuStyles, anchorEl],
  );
  const IconButtonStyled = useMemo(() => withStyles(merge(buttonDefaultStyles, buttonStyles))(Button), [buttonStyles]);

  /** clone button adding click to open menu and aria props */
  const renderButton = () => {
    // default button
    const buttonMenu = button || (
      <IconButtonStyled data-testid="DropdownMenu-Button">
        {light ? <MoreVertIconLight /> : <MoreVertIconDark />}
      </IconButtonStyled>
    );

    const extraProps = {
      'aria-owns': open ? 'fade-menu' : undefined,
      'aria-haspopup': true,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        handleOpen(e);
        if (buttonMenu.props.onClick) buttonMenu.props.onClick(e);
      },
    };

    const buttonWithExtras = React.cloneElement(buttonMenu, extraProps, buttonMenu.props.children);

    return buttonWithExtras;
  };

  /** clone each menu item wrapping onClick to close menu */
  const renderItems = () =>
    React.Children.map(children, child =>
      !child || typeof child === 'number' || typeof child === 'string'
        ? child
        : React.cloneElement(child, {
            onClick: (e: React.MouseEvent<HTMLElement>) => {
              handleClose();
              if (child.props.onClick) child.props.onClick(e);
            },
          }),
    );

  return (
    <>
      {!hideButton && renderButton()}
      {children && (
        <StyledMenu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
          {renderItems()}
        </StyledMenu>
      )}
    </>
  );
};

const buttonDefaultStyles = createStyles({
  root: {
    color: colors.accentDarkBlue,
    textTransform: 'none',
    minWidth: 0,
    height: 32,
    padding: '0 5px',
  },
});

const menuDefaultStyles = (menuMarginTop: number) =>
  createStyles({
    paper: {
      marginTop: menuMarginTop,
      borderRadius: '2px',
      backgroundColor: '#3c434b',
    },
  });

const MoreVertIconLight = styled(MoreVertIcon)`
  color: white;
`;

const MoreVertIconDark = styled(MoreVertIcon)`
  color: ${colors.accentDarkBlue};
`;

export const DropdownMenuItem = styled.div<{ style?: React.CSSProperties; active?: boolean; disabled?: boolean }>`
  min-height: 28px;
  margin: 0px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  background-color: ${props => (props.active ? 'var(--default-blue)' : 'inherit')};
  &:hover {
    background-color: ${props => (props.active ? 'var(--default-blue)' : '#474e56')};
  }
  color: var(--text);
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export const DropdownMenuSelectableItem = styled(DropdownMenuItem)`
  background-color: var(--dropdownBackground);
  &:hover {
    background-color: var(--dropdownHoveredLabel);
  }
`;

export const DropdownMenuButton = styled(DropdownMenuItem)`
  background-color: var(--grey);
  &:hover {
    background-color: var(--grey);
  }
`;

export const DropdownMenuIconWrapper = styled.div<any>`
  margin-right: 6px;
  display: flex;

  svg {
    color: ${props => (props.disabled ? 'rgba(255,255,255, 0.5)' : colors.text)};
  }
`;

export const DropdownMenuItemText = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: ${props => (props.disabled ? 'var(--grey)' : 'var(--text)')};
`;

export default DropdownMenu;
