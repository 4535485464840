import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions, B2CConfig } from 'src/auth';
import mockUser from 'src/utils/mockUser';
import { getType } from 'typesafe-actions';
import { PayloadAction } from 'typesafe-actions/dist/types';
import { b2cLogin, getUser, loginRedirect, logout, User } from '../msalWrapper';

const RETURN_URL_KEY = 'RETURN_URL';

function* initialize(config: B2CConfig) {
  try {
    const token = yield call(b2cLogin, config);
    const user: User | any = getUser();
    if (user && token) {
      // returned from login redirect with an authenticated user
      yield put(authActions.loginSuccess({ user, token }));

      // return to the requested url before login redirect (if set)
      const returnUrl = sessionStorage.getItem(RETURN_URL_KEY);
      if (returnUrl && returnUrl.length > 0) {
        history.replaceState(null, document.title, returnUrl);
      }
    }
  } catch (e) {
    console.error(`Error in authorize flow`, e);
    yield put(authActions.loginError(e));
  } finally {
    sessionStorage.removeItem(RETURN_URL_KEY);
  }
}

function* authInit(initializeAction: PayloadAction<string, any>) {
  if (__USE_MOCK_DATA__) {
    yield put(authActions.loginSuccess({ user: mockUser, token: '123', glide: { token: 'glideToken-123' } }));
  } else {
    yield call(initialize, initializeAction.payload);
  }

  yield put(authActions.initialized());
}

function* b2CLogin() {
  if (__USE_MOCK_DATA__) {
    yield put(authActions.loginSuccess({ user: mockUser, token: '123', glide: { token: 'glideToken-123' } }));
  } else {
    sessionStorage.setItem(RETURN_URL_KEY, location.href);
    loginRedirect();
  }
}

function nexusLogout() {
  if (!__USE_MOCK_DATA__) logout();
}

function* watchAuthInitialize() {
  if (typeof Cypress !== 'undefined') {
    const auth = Cypress.env('auth');
    yield put(authActions.initialized());
    yield put(authActions.loginSuccess({ user: mockUser, token: auth.access_token }));
    return auth.access_token;
  }
  yield takeLatest(getType(authActions.initialize), authInit);
}

function* watchNexusLogin() {
  yield takeLatest(getType(authActions.login), b2CLogin);
}

function* watchNexusLogout() {
  yield takeLatest(getType(authActions.logout), nexusLogout);
}

// function* watchUnauthorized() {
//   // If a request failed due to an unauthorized token, throw away the cached token and reset the auth state
//   // TODO: What to do here?
//   yield takeEvery(getType(authActions.unauthorized), clearCache);
// }

export default [watchAuthInitialize, watchNexusLogin, watchNexusLogout];
