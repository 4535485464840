import { AxiosInstance, AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { DELETE, GET, HEAD, PATCH, POST, PUT } from '../core-services/CoreServicesClient';

export interface MockRoute {
  [key: string]: any;
}

const RESPONSE_CODE = 200;
const HEADERS = { 'content-type': 'application/json' };

export const route = (path = '') => {
  return typeof path === 'string' ? new RegExp(path.replace(/:\w+/g, '[^/]+')) : path;
};

export const extractPathParams = (urlPattern: string, requestUrlArg: string) => {
  const params: { [key: string]: string } = {};
  const urlPatternArr = urlPattern.split('/').slice(1);

  // convert the requested url to a relative path that matches the url pattern
  const hostPath = requestUrlArg.split(`/${urlPatternArr[0]}`)[0];
  const requestUrlArr = requestUrlArg
    .replace(hostPath, '')
    .split('/')
    .slice(1);

  urlPatternArr.forEach((token: string, index: number) => {
    if (!token.startsWith(':')) return;
    params[token.substr(1)] = requestUrlArr[index];
  });

  return params;
};

const handle = (
  config: AxiosRequestConfig,
  urlPattern: string,
  dataFunc: (url: string, options: any, config: AxiosRequestConfig) => any,
): [number, any, { ['content-type']: string }] => {
  const url = config.url || '';
  const params = { ...config.params, ...extractPathParams(urlPattern, url) };
  const mockedData = dataFunc(url, config.data ? JSON.parse(config.data) : {}, params);
  return [RESPONSE_CODE, mockedData, HEADERS];
};

const loadMockData = (axios: AxiosInstance, mockRoutes: MockRoute) => {
  try {
    const mockAdapter = new MockAdapter(axios, { delayResponse: 1000 });
    Object.keys(mockRoutes).forEach(key => {
      const mockRouteObj: any = (mockRoutes as MockRoute)[key];

      if (mockRouteObj) {
        Object.keys(mockRouteObj).forEach(method => {
          switch (method) {
            case GET:
              mockAdapter.onGet(route(key)).reply(config => handle(config, key, mockRouteObj[method]));
              break;
            case POST:
              mockAdapter.onPost(route(key)).reply(config => handle(config, key, mockRouteObj[method]));
              break;
            case PUT:
              mockAdapter.onPut(route(key)).reply(config => handle(config, key, mockRouteObj[method]));
              break;
            case PATCH:
              mockAdapter.onPatch(route(key)).reply(config => handle(config, key, mockRouteObj[method]));
              break;
            case DELETE:
              mockAdapter.onDelete(route(key)).reply(config => handle(config, key, mockRouteObj[method]));
              break;
            case HEAD:
              mockAdapter.onHead(route(key)).reply(config => handle(config, key, mockRouteObj[method]));
              break;
          }
        });
      }
    });
  } catch (err) {
    console.error('Error loading mock routes', err);
  }
};

export default loadMockData;
