import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

// tslint:disable:max-line-length
const PausedIcon: React.FunctionComponent<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path fill="#B3B3B3" d="M6 2v12H3V2h3zm7 0v12h-3V2h3z" />
    </g>
  </SvgIcon>
);

export default PausedIcon;
