import { call } from 'redux-saga/effects';

/**
 * Call this from createStore to run all sagas as their own root.
 *
 * Will Restart each saga if it catches an error and console.error it
 * This stops one error bubbling to parent and killing all sagas
 *
 * @param sagaMiddleware pass in from redux init
 * @param sagas pass in array of all sagas to start on app startup
 */
export const runSagas = (sagaMiddleware: any, sagas: Array<() => IterableIterator<any>>) => {
  sagas.forEach(saga =>
    sagaMiddleware.run(function*() {
      let startupSuccessful = true;
      while (startupSuccessful) {
        startupSuccessful = false;
        try {
          // eslint-disable-next-line no-loop-func
          setTimeout(() => {
            startupSuccessful = true;
          });
          // start saga
          yield call(saga);
        } catch (e) {
          const name = saga.name;
          const stack = e.sagaStack;
          if (!startupSuccessful) {
            throw new Error(`Saga '${name}' exception on startup: ${e}`);
          } else {
            console.warn(stack);
            console.error(`Error in saga '${name}'. Restarting: ${e}`, e);
          }
        }
      }
    }),
  );
};
