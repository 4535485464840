import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

// tslint:disable:max-line-length
const CartWithCounter: React.SFC<SvgIconProps & { counter: number; style?: React.CSSProperties }> = props =>
  props.counter ? (
    <SvgIcon width="27px" height="25px" viewBox="0 0 30 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="🚀-DROP-DESIGNS-HERE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="OMS-Multi-Select-1" transform="translate(-1000.000000, -112.000000)">
          <g id="Group-3-Copy" transform="translate(10.000000, 96.000000)">
            <g id="Group-11">
              <g id="Icon/24x24/search" transform="translate(989.000000, 16.000000)">
                <g id="Group-5">
                  <g id="Group-6-Copy">
                    <g id="Icon/24x24/order_count">
                      <g id="Icon/24x24/search-Copy">
                        <g>
                          <g id="Icon/24x24/order">
                            <polygon id="frame" points="0 4 24 4 24 28 0 28" />
                            <path
                              d="M7,22 C8.1,22 9,22.9 9,24 C9,25.1 8.1,26 7,26 C5.9,26 5.01,25.1 5.01,24 C5.01,22.9 5.9,22 7,22 Z M17,22 C18.1,22 19,22.9 19,24 C19,25.1 18.1,26 17,26 C15.9,26 15.01,25.1 15.01,24 C15.01,22.9 15.9,22 17,22 Z M4.27,6 L5.21,8 L13.0620129,8.00095508 C13.432283,10.9673802 15.4266815,13.4292967 18.1272109,14.4687067 L17.3,15.97 C16.96,16.59 16.3,17 15.55,17 L15.55,17 L8.1,17 L7.2,18.63 L7.17,18.75 C7.17,18.89 7.28,19 7.42,19 L7.42,19 L19,19 L19,21 L7,21 C5.9,21 5,20.1 5,19 C5,18.65 5.09,18.32 5.25,18.04 L5.25,18.04 L6.6,15.59 L3,8 L1,8 L1,6 L4.27,6 Z"
                              id="Combined-Shape"
                              fill={props.style ? props.style.color : '#5A5D5F'}
                            />
                            <rect id="Rectangle" fill="#0D83C9" x="14" y="0" width="16" height="14" rx="7" />
                            <text
                              id="15"
                              x="70%"
                              y="31%"
                              fontSize="11"
                              fontWeight="800"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="#FFFFFF"
                            >
                              {props.counter}
                            </text>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  ) : (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g fill="none" fillRule="evenodd">
        <path d="M0 8h48v48H0z" />
        <path
          fill={props.style ? props.style.color : '#5A5D5F'}
          d="M14 44c-2.2 0-3.98 1.8-3.98 4s1.78 4 3.98 4 4-1.8 4-4-1.8-4-4-4zM2 12v4h4l7.2 15.18-2.7 4.9c-.32.56-.5 1.22-.5 1.92 0 2.2 1.8 4 4 4h24v-4H14.84c-.28 0-.5-.22-.5-.5l.06-.24L16.2 34h14.9c1.5 0 2.82-.82 3.5-2.06l7.16-12.98c.16-.28.24-.62.24-.96 0-1.1-.9-2-2-2H10.42l-1.88-4H2zm32 32c-2.2 0-3.98 1.8-3.98 4s1.78 4 3.98 4 4-1.8 4-4-1.8-4-4-4z"
        />
      </g>
    </SvgIcon>
  );

export default CartWithCounter;
