import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

// tslint:disable:max-line-length
const CreateCopyIcon: React.FunctionComponent<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#FFF"
        d="M6 3c-1.1 0-2 .9-2 2v12h2V5h10V3H6zm3 3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2H9zm0 14V8h9v12H9z"
      />
    </g>
  </SvgIcon>
);

export default CreateCopyIcon;
