import { Button, Dialog } from '@material-ui/core';
import { Close, Help } from '@material-ui/icons';
import React from 'react';
import { DangerButton, PrimaryButton, TertiaryButton } from 'src/Buttons';
import styled from 'styled-components';

const HelpIcon = styled(Help)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: #fff;
`;

const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: #fff;
`;

const IconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: #fff;
`;

const StyledDialog = styled.div`
  position: relative;
  width: auto;
  height: auto;
  background: #1b252f;
  color: #fff;
  overflow: auto;
`;

const ButtonSpacing = styled.div`
  width: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: #293a49;
  padding-left: 20px;
  padding-right: 10px;
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #28333c;
  display: flex;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
`;

interface ModalButton {
  text: string;
  action: (e: any) => void;
  disabled?: boolean;
}

export interface ModalProps {
  title?: string;
  helpText?: string;
  onClose: () => void;
  onHelp?: () => void;
  children: React.ReactChild;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  fullWidth?: boolean;
  show: boolean;
  headerStyle?: any;
  footerStyle?: any;
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
  dangerButton?: ModalButton;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  dataTestId?: boolean;
}

const Modal = ({
  title,
  helpText,
  onHelp = () => alert('You hit help'),
  onClose,
  children,
  show,
  primaryButton,
  secondaryButton,
  dangerButton,
  footer,
  header,
  headerStyle,
  footerStyle,
  maxWidth,
  fullWidth,
  hideHeader,
  hideFooter,
  dataTestId,
}: ModalProps) => {
  return (
    <Dialog
      data-testid={dataTestId || 'modal'}
      open={show}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="create-portfolio-group"
    >
      <StyledDialog>
        {!hideHeader && (
          <Header data-testid={'modal-header'} style={headerStyle}>
            {header || (
              <>
                <h4 data-testid="dialog-title">{title}</h4>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {helpText && (
                    <IconButton data-testid="modal-help" onClick={onHelp}>
                      <HelpIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </>
            )}
          </Header>
        )}

        {children}
        {!hideFooter && (
          <Footer data-testid="modal-footer" style={footerStyle}>
            {footer || (
              <>
                {secondaryButton && (
                  <TertiaryButton onClick={secondaryButton.action}>{secondaryButton.text}</TertiaryButton>
                )}
                <ButtonSpacing />
                {primaryButton && (
                  <PrimaryButton onClick={primaryButton.action} disabled={primaryButton.disabled}>
                    {primaryButton.text}
                  </PrimaryButton>
                )}
                {dangerButton && (
                  <DangerButton onClick={dangerButton.action} disabled={dangerButton.disabled}>
                    {dangerButton.text}
                  </DangerButton>
                )}
              </>
            )}
          </Footer>
        )}
      </StyledDialog>
    </Dialog>
  );
};

export default Modal;
