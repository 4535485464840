// tslint:disable (Handler for Glide meant for dev only)
import { B2CConfig } from '@virtus/common/auth';
import { User } from '@virtus/common/auth/msalWrapper';
import { authActions, authSelectors, GlideAuthError, RootState } from '@virtus/common/auth/reducer';
import { usePrevious } from '@virtus/common/utils/hooks/usePrevious';
import PageError from '@virtus/components/page/page-error';
import React, { createContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { GlideAppConfig } from 'src/config';
import { ActionAuthParams } from 'src/utils/action-resolver';

interface ReduxProps {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isGlideAuthenticated: boolean;
  user: User | null;
  glideAuthError: GlideAuthError | any;
  readonly glideToken: any;
  readonly glideEnvironment: any;
}

interface OwnProps {
  children: (state: AuthenticationHandlerRenderProps) => React.ReactNode;
  config: GlideAppConfig;
}

interface UserDispatch {
  login: (arg?: any) => void;
  glideLogin: (arg: any) => void;
  logout: () => void;
}

interface ReduxDispatch {
  initializeAuth: (arg: B2CConfig) => void;
}

type Dispatch = ReduxDispatch & UserDispatch;

export type AuthenticationHandlerRenderProps = {
  isAuthenticated: boolean;
  isGlideAuthenticated: boolean;
  user: User | null;
  actionAuth: ActionAuthParams;
} & UserDispatch;

export type AuthenticationHandlerProps = ReduxProps & Dispatch & OwnProps;

export const AuthContext = createContext({});

const AuthenticationHandlerGlide = ({
  children,
  glideToken,
  glideEnvironment,
  isAuthenticated,
  logout,
  initializeAuth,
  login,
  glideLogin,
  isInitialized,
  user,
  isGlideAuthenticated,
  config,
  glideAuthError,
}: AuthenticationHandlerProps) => {
  useEffect(() => {
    initializeAuth(config.b2c);
  }, []);

  const actionResolverAuthParams: ActionAuthParams = {
    glide_token: glideToken,
    environment: glideEnvironment.environment,
  };

  const previousInitialized = usePrevious(isInitialized);
  useEffect(() => {
    if (!previousInitialized && isInitialized && !user) {
      login(config.b2c);
    } else if (previousInitialized && user && !isGlideAuthenticated) {
      const urlParams = new URLSearchParams(window.location.search);
      glideLogin({ ...config.glide, username: user.idToken.name, environment: urlParams.get('api_env') });
    }
  });

  return (
    <>
      {glideAuthError && (
        <PageError
          statusCode={glideAuthError.statusCode}
          statusMessage={glideAuthError.statusMessage}
          footerMessage="Please contact support: support@alphakinetic.com"
        />
      )}
      {isInitialized &&
        (user || isGlideAuthenticated) &&
        children({
          isAuthenticated,
          isGlideAuthenticated,
          glideLogin,
          login,
          logout,
          user,
          actionAuth: actionResolverAuthParams,
        })}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  isInitialized: authSelectors.getStatus(state) === 'initialized',
  user: authSelectors.getUser(state),
  isAuthenticated: authSelectors.isAuthenticated(state),
  isGlideAuthenticated: authSelectors.isGlideAuthenticated(state),
  glideAuthError: authSelectors.glideAuthError(state),
  glideToken: authSelectors.glideAuthToken(state),
  glideEnvironment: authSelectors.glideEnvironment(state),
});

const mapDispatchToProps: Dispatch = {
  initializeAuth: authActions.initialize,
  login: authActions.login,
  logout: authActions.logout,
  glideLogin: authActions.glideLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandlerGlide);
