import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import 'css-vars-ponyfill';
import React from 'react';
import { muiTheme } from 'src/muiTheme';
import { colors, fontFamily } from 'src/theme';
import JssOrder from './JssOrder';
import './theme.css';

export interface VirtusThemeProps {
  children: React.ReactNode;
}

/**
 * The Material UI theme to apply + CSS Reset
 *
 * Also fixes css import order so styled components 2nd, after material UI JSS
 *
 * Use one of first components at root of <App/>
 */
const VirtusTheme: React.SFC<VirtusThemeProps> = ({ children }) => (
  <JssOrder>
    <>
      <CssBaseline />
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </>
  </JssOrder>
);

// set default font-face too using JSS
// Therefore not just `<Typography>` material ui components have the nice font
export default withStyles({
  '@global': {
    body: {
      fontFamily,
      backgroundColor: colors.bg, // also set bg after CssBaseline resets it
      overflow: 'hidden',
      scrollbarColor: '#60666d94 transparent' /* thumb and track color */,
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      position: 'absolute',
    },

    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#60666d94',
      borderRadius: '4px',
    },

    '*::-webkit-scrollbar-button': {
      display: 'none',
    },
    '*::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
    '.react-grid-item.react-grid-placeholder': {
      backgroundColor: `${colors.accent} !important`,
    },
    '*': {
      outline: 'none',
    },
  },
})(VirtusTheme);
