import { put, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/actions';

function* clearNotifications() {
  // Reset errors
  yield put({
    type: actions.errors.RESET,
  });
}

function* watchClearNotification() {
  yield takeLatest(actions.notifications.RESET, clearNotifications);
}

export default [watchClearNotification];
