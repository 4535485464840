import { ComplianceIcon, HyposIcon, PortfoliosIcon } from '@virtus/components/icons';
import { Route } from '@virtus/components/page/models/route';
import React, { ComponentType } from 'react';
import { NavigationProps } from 'src/components/PageWrapper';

export interface GlideRoute extends Route {
  component: ComponentType<NavigationProps & any>;
}

const routes: GlideRoute[] = [
  {
    icon: {
      component: PortfoliosIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Assets',
    path: 'universe-assets',
    fullPath: '/universe-assets',
    aliases: ['/'],
    component: React.lazy(() => import(/* webpackChunkName: "AssetsPage" */ 'src/pages/Assets/Assets')),
  },
  {
    icon: {
      component: ComplianceIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Orders',
    path: 'orders',
    fullPath: '/orders',
    component: React.lazy(() => import(/* webpackChunkName: "OrdersPage" */ 'src/pages/Orders/Orders')),
  },
  {
    icon: {
      component: HyposIcon,
      sideBarProps: { style: { width: '16px' } },
      enableInSideBar: true,
    },
    name: 'Hypos',
    path: 'hypos',
    fullPath: '/hypos',
    component: React.lazy(() => import(/* webpackChunkName: "HyposPage" */ 'src/pages/Hypos/Hypos')),
  },
];

export default routes;
