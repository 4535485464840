import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: var(--text);
  height: 35vh;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StatusCode = styled.div`
  text-align: center;
  color: var(--virtus-orange);
  font-size: 100px;
`;

export const StatusMessage = styled.div`
  font-size: 36px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-left: 20px;
`;

export const FooterMessage = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
`;
