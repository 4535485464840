import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { variables } from 'src/theme';
import styled from 'styled-components';

// tslint:disable:max-line-length
const GlideIcon: React.SFC<Omit<SvgIconProps, 'color'> & { color?: string }> = ({ color, ...props }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <ellipse cx="9.996" cy="10.1" fill={color || '#FFF'} rx="3.431" ry="3.45" />
      <path
        fill={color || '#0D83C9'}
        fillRule="nonzero"
        d="M9.431 11.242c.032.025.065.05.1.075.938.685 2.512.79 3.674 0-1.705-.314-2.04-.907-2.273-1.24.25-.278.658-.883 2.237-.957-1.12-1.445-2.325-.791-3.077-.08-.102.125-.527.134-.648 0-.807-1.06-1.64-.816-2.073-.28.738.324.839.862 1.187 1.476-.317.258-.848.522-1.857.569.926 1.194 1.982.957 2.73.437zm5.587-4.126a5.819 5.819 0 0 0-5.072-2.973C6.73 4.143 4.121 6.765 4.121 10c0 3.235 2.608 5.857 5.825 5.857a5.817 5.817 0 0 0 5.028-2.897l.76-1.242 2.321 4.074C16.253 18.339 13.292 20 9.946 20 4.453 20 0 15.523 0 10S4.453 0 9.946 0c3.362 0 6.334 1.677 8.135 4.244l-2.332 4.09-.731-1.218zm4.046-1.118c.533 1.225.829 2.579.829 4.002 0 1.436-.301 2.8-.843 4.035l-2.283-4.006 2.297-4.031zm-9.189-.212c2.065 0 3.699 1.404 4.902 4.214-1.203 2.81-2.837 4.214-4.902 4.214-2.315 0-4.191-1.887-4.191-4.214 0-2.327 1.876-4.214 4.191-4.214z"
      />
    </g>
  </SvgIcon>
);

/** correct font size for `<Header>` */
export const GlideHeaderIcon: React.SFC<{}> = () => (
  <StyledHeaderIcon>
    <GlideIcon fontSize="inherit" />
  </StyledHeaderIcon>
);

const StyledHeaderIcon = styled.span`
  font-size: ${variables.headerIconFontSize};
`;
export default GlideIcon;
