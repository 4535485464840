import axios from 'axios';
import { Guid } from 'guid-typescript';
import publicIp from 'public-ip';
import { getAccessToken } from 'src/auth';

export async function doLogin(userLoginApiUrl: string, appWebName: string, usrName: string) {
  const rowKey = Guid.create();
  let gatewayIp: string = '';
  let localIp: any = '';
  try {
    gatewayIp = await publicIp.v4();
  } catch (e) {
    gatewayIp = '';
  }

  if (navigator.userAgent.match(/Edge/) == null && navigator.userAgent.match(/trident/i) == null) {
    const findIP = new Promise((resolve, reject) => {
      // tslint:disable-next-line: one-variable-per-declaration
      const w = window,
        a = new w.RTCPeerConnection({ iceServers: [] }),
        // tslint:disable-next-line: no-empty
        b = () => {};
      a.createDataChannel('');
      a.createOffer((c: any) => a.setLocalDescription(c, b, b), b);
      a.onicecandidate = (c: any) => {
        if (c.candidate != null) {
          try {
            const ipMatches = c.candidate.candidate.match(
              /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
            );
            if (Array.isArray(ipMatches) && ipMatches.length > 0) {
              ipMatches.forEach(resolve);
            } else {
              reject('');
            }
          } catch (e) {
            console.log(e);
          }
        }
      };
    });

    await findIP
      .then(ip => {
        localIp = ip;
      })
      .catch(() => {
        localIp = '';
      });
  }

  const token = await getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  axios.post(
    userLoginApiUrl,
    {
      Parameters: {
        PartitionKey: appWebName,
        RowKey: rowKey[`value`],
        UserName: usrName,
        LoginTimeStamp: new Date(),
      },
      MessageType: 'User Login',
      GatewayIp: gatewayIp,
      LocalIp: localIp,
    },
    config,
  );
}
