export { authActions } from './reducer';
export { getAccessToken } from './msalWrapper';
import { MockRoute } from '../utils/loadMockData';

export interface AppConfig {
  appInsightsKey?: string;
  b2c: B2CConfig;
}

export interface GlideConfig {
  baseURL: string;
  mockRoutes?: MockRoute;
  API_WEB_SOCKET_URL?: string;
  environment?: string;
}

export interface B2CConfig {
  appName: string;
  clientId: string;
  tenantId: string;
  b2cSigninPolicy: string;
  userLoginApiUrl: string;
  b2cPasswordResetPolicy: string;
}

export interface NexusConfig {
  baseURL: string;
  signalrUrl: string;
  signalrClientId: string;
}

export interface PortalConfig extends AppConfig {
  glide: GlideConfig;
  b2c: B2CConfig;
  nexus: NexusConfig;
}
