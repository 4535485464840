import { Location, LocationContext } from '@reach/router';
import { authSelectors, RootState } from '@virtus/common/auth/reducer';
import { VirtusTheme } from '@virtus/components';
import { AppNames } from '@virtus/components/NavigationBar/components/AppMenu/AppMenu';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { StyledApp, StyledRouter } from 'src/app/App/App.style';
import AuthenticationHandlerGlide, { AuthContext } from 'src/app/App/AuthenticationHandlerGlide';
import config, { GlideAppConfig } from 'src/config';
import configureHeader from 'src/config/headerConfig';
import './App.css';

import routes, { GlideRoute } from '../routes/routes';
interface ReduxProps {
  getUsername: () => string;
}

interface ReduxDispatch {}

interface OwnProps {
  config: GlideAppConfig;
}

const Header = React.lazy(() => import('@virtus/components/page/Header'));
const NotFound = React.lazy(() => import('@virtus/components/page/NotFound'));

const headerConfiguration = configureHeader(routes);

// Needed to be able to target Glide website from other portal apps
window.name = 'glide';

/** Theme, Header and Routing */
const App: React.FunctionComponent<ReduxProps & ReduxDispatch & OwnProps> = ({ getUsername }) => (
  <Suspense fallback={<div />}>
    <VirtusTheme>
      <AuthenticationHandlerGlide config={config}>
        {({ isGlideAuthenticated, login, logout, actionAuth }) => (
          <StyledApp>
            <Location>
              {(location: LocationContext) =>
                isGlideAuthenticated && (
                  <Header
                    {...headerConfiguration}
                    appMenuValue="Glide"
                    isLoggedIn={isGlideAuthenticated}
                    topRightButtonText={getUsername()}
                    path={location.location.pathname}
                    topRightMenuItems={[{ text: 'Logout', onClick: logout }]}
                    onLogin={login}
                    enabledApps={[AppNames.nexus, AppNames.glide]}
                    appColor={'var(--default-blue)'}
                  />
                )
              }
            </Location>
            {isGlideAuthenticated && (
              <AuthContext.Provider value={actionAuth}>
                <StyledRouter>
                  {routes.map((route: GlideRoute) => {
                    const routeElements: any[] = [];
                    if (route.aliases) {
                      route.aliases.map((alias: string) =>
                        routeElements.push(<route.component key={alias} path={alias} />),
                      );
                    }
                    routeElements.push(<route.component path={route.path} />);
                    return routeElements;
                  })}

                  {/* TODO design for 404 https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/32837/ */}
                  <NotFound default />
                </StyledRouter>
              </AuthContext.Provider>
            )}
          </StyledApp>
        )}
      </AuthenticationHandlerGlide>
    </VirtusTheme>
  </Suspense>
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  getUsername: () => authSelectors.getUsername(state),
});

export default connect(mapStateToProps)(App);
