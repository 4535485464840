import { GlideConfig, NexusConfig, PortalConfig } from 'src/auth/index';
import { CoreServicesClientConfig } from 'src/core-services/CoreServicesClient';
import { GlideAPIClient } from 'src/core-services/GlideAPIClient';
import createCoreServicesNetworkInterface, { NetworkHandler } from 'src/core-services/reduxQueryNetworkInterface';

class NetworkInterface {
  glideAPIClient?: GlideAPIClient;
  csClientConfig?: CoreServicesClientConfig;
  glideCsClientConfig?: {
    baseURL: string;
    mockRoutes?: object;
  };

  constructor(props: Partial<NexusConfig & PortalConfig & GlideConfig & CoreServicesClientConfig>) {
    // Store app config to create new client on RQ request
    if ((props as PortalConfig).glide) {
      this.glideCsClientConfig = {
        baseURL: (props as PortalConfig).glide.baseURL,
        mockRoutes: (props as CoreServicesClientConfig).mockRoutes,
      };
    }

    if ((props as PortalConfig).nexus) {
      this.csClientConfig = {
        baseURL: (props as PortalConfig).nexus.baseURL,
        signalrUrl: (props as PortalConfig).nexus.signalrUrl,
        onUnauthorized: (props as CoreServicesClientConfig).onUnauthorized,
        getUsername: (props as CoreServicesClientConfig).getUsername,
        mockRoutes: (props as CoreServicesClientConfig).mockRoutes,
      };
    }
  }

  // The network interface is routed to Glide or Nexus
  reduxQueryNetworkInterface(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    config: { body?: any; headers?: object },
  ): NetworkHandler {
    // Glide API uses a prefix. Easy.
    if (url.includes('glide') && this.glideCsClientConfig?.baseURL) {
      this.glideAPIClient = new GlideAPIClient(this.glideCsClientConfig);
      return this.glideAPIClient.reduxQueryNetworkInterface(url, method, config);
    }

    // The rest of queries go to Nexus for now..
    if (this.csClientConfig) return createCoreServicesNetworkInterface(this.csClientConfig)(url, method, config);
    throw Error(`route ${url} has no matching network interface!`);
  }
}

export default NetworkInterface;
