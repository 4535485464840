import { Button, Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Help from '@material-ui/icons/Help';
import React from 'react';
import styled from 'styled-components';
import { DangerButton, TertiaryButton } from '../Buttons';
import { ConfirmDialogProps } from './withConfirmationDialogOnClick';

const DefaultConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = ({
  headerText,
  bodyTitle,
  bodyTextContent,
  acceptButton,
  cancelButton,
  onAccept,
  onCancel,
  onClose,
  onHelp,
  show,
}: ConfirmDialogProps) => (
  <Dialog open={show} onClose={onClose} aria-labelledby="confirm-dialog" data-testid="default-confirm-dialog">
    <DefaultConfirmDialogWrapper>
      <TopBar>
        {headerText}
        <Icons>
          {onHelp && (
            <IconButton onClick={onHelp}>
              <HelpIcon />
            </IconButton>
          )}
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Icons>
      </TopBar>
      <Content>
        <BodyTitle>{bodyTitle}</BodyTitle>
        <span>{bodyTextContent}</span>
      </Content>
      <BottomBar>
        {onCancel && (
          <TertiaryButton
            data-testid={cancelButton?.dataTestId || 'cancel-btn'}
            onClick={onCancel}
            style={cancelButton && cancelButton.style}
          >
            {(cancelButton && cancelButton.text) || 'Cancel'}
          </TertiaryButton>
        )}
        <DangerButton
          data-testid={acceptButton?.dataTestId || 'confirm-btn'}
          onClick={onAccept}
          style={{ marginLeft: '1em', ...(acceptButton && acceptButton.style) }}
        >
          {(acceptButton && acceptButton.text) || 'Confirm'}
        </DangerButton>
      </BottomBar>
    </DefaultConfirmDialogWrapper>
  </Dialog>
);

const DefaultConfirmDialogWrapper = styled.div`
  width: 440px;
  height: 192px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--bgDark);
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: none;
  color: white;
`;

const Bar = styled.div`
  height: 48px;
  background-color: var(--bg);
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const BottomBar = styled(Bar)`
  justify-content: flex-end;
`;

const TopBar = styled(Bar)`
  justify-content: space-between;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: 0.5em 1em;
`;

const BodyTitle = styled.span`
  font-weight: bold;
`;

export default DefaultConfirmDialog;

const IconButton: any = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: var(--text);
`;

const HelpIcon = styled(Help)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--text);
`;

const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--text);
`;
