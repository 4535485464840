import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

// tslint:disable:max-line-length
const Hypos: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        fill="#FFF"
        d="M8 0c1.011 0 1.867.672 2.193 1.6h3.251C14.3 1.6 15 2.32 15 3.2v11.2c0 .88-.7 1.6-1.556 1.6H2.556C1.7 16 1 15.28 1 14.4V3.2c0-.88.7-1.6 1.556-1.6h3.25C6.134.672 6.99 0 8 0zm.75 13.505h-1.5V15h1.5v-1.495zM8 5C6.343 5 5 6.338 5 7.99h1.5c0-.822.675-1.495 1.5-1.495s1.5.673 1.5 1.496c0 .41-.165.785-.443 1.054l-.93.942a3.005 3.005 0 0 0-.877 2.116v.374h1.5c0-1.122.338-1.57.877-2.116l.675-.688A2.375 2.375 0 0 0 11 7.99 2.995 2.995 0 0 0 8 5zm0-3.26a.864.864 0 0 0-.854.869c0 .478.384.87.854.87s.854-.392.854-.87A.864.864 0 0 0 8 1.739z"
      />
    </g>
  </SvgIcon>
);

export default Hypos;
